import React, {useEffect, useState} from "react";
import Autosuggest from 'react-autosuggest';
import config from '../../../config';

const GeoAutoSuggest = ({setSearchQuery}) => {
    const [customGeoLocations, setCustomGeoLocations] = useState(null);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        fetch(`/wp-json/v1/custom-geo-locations`)
            .then(response => response.json())
            .then(data => setCustomGeoLocations(data?.custom_locations))
    }, [])

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const mapboxResults = inputLength === 0 ? [] : results.filter(lang =>
            lang.text_nl.toLowerCase().slice(0, inputLength) === inputValue
        );

        const customGeoResults = inputLength === 0 ? [] : customGeoLocations.filter(item =>
            item.location.toLowerCase().slice(0, inputLength) === inputValue
        );

        return [...customGeoResults, ...mapboxResults];
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = location => {
        const locationQuery = location?.custom_location ? {
            location: location.location,
            customLocation: true,
            page: 0,
            directLocation: location.location

        } : {
            location: location.id,
            customLocation: false,
            page: 0,
            directLocation: location.text
        }
        setSearchQuery(prevState => {
            return {
                ...prevState,
                ...locationQuery
            }
        })

        return location?.custom_location ? location.location : location.text_nl;
    }

    const getCountry = (context, countryCode = false) => {
        let country;
        if (!context) return null;
        context.forEach(item => {
            if (item.id.startsWith('country')) {
                country = countryCode ? item.short_code.toUpperCase() : item.text_nl
            }
        });

        return country;
    }

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => {
        const countryCode = !suggestion.custom_location && getCountry(suggestion?.context, true);

        return (
            <div>
                {!suggestion.custom_location ? (
                    <p className="py-2 mb-0" style={{marginLeft: !countryCode && 30}}>
                        {countryCode && (
                            <img
                                style={{width: 25, marginRight: 5}}
                                alt={getCountry(suggestion?.context)}
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
                            />
                        )}

                        {suggestion.text_nl}{suggestion.context && `, ${getCountry(suggestion?.context)}`} <span
                        style={{fontSize: 13}}>
                        (
                        {suggestion?.place_type[0] === 'place' && 'plaats'}
                        {suggestion?.place_type[0] === 'region' && 'regio'}
                        {suggestion?.place_type[0] === 'neighborhood' && 'buurt'}
                        {suggestion?.place_type[0] === 'country' && 'land'}
                        {suggestion?.place_type[0] === 'locality' && 'streek'}
                        )
                        </span>
                    </p>
                ) : (
                    <p className="py-2 mb-0" style={{marginLeft: 30}}>
                        {suggestion.location} <span style={{fontSize: 13}}>({suggestion.type_location})</span>
                    </p>
                )}
            </div>
        );
    }

    const onChange = (event, {newValue}) => {
        setValue(newValue)

        if (newValue === "") {
            setSearchQuery(prevState => {
                return {
                    ...prevState,
                    customLocation: false,
                    location: '',
                    directLocation: '',
                    page: 0
                }
            })
        }
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({value}) => {
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?` + new URLSearchParams({
            access_token: config.MAPBOX_ACCESS_TOKEN,
            autocomplete: true,
            language: 'nl',
            types: 'country,region,place,neighborhood,locality',
            bbox: '-21.806154544197398,35.09016005807915,29.416762645744512,72.81283776802888'
        }))
            .then(response => response.json())
            .then(data => setResults(data?.features))
            .then(() => setSuggestions(getSuggestions(value)))
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Waarheen (regio, gebied, plaats)',
        className: "form-control",
        value,
        onChange: onChange
    };

    return <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
    />;
}

export default GeoAutoSuggest