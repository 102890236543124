import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import GeoAutoSuggest from "../../properties/components/filter/GeoAutoSuggest";
import { Paper, MultiSelect, Select } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
require('prerender-node').set('prerenderToken', 'wMADexqDXM0hV4dBCKik');

const FrontPageHeroSearch = () => {
    const [searchQuery, setSearchQuery] = useState({ location: null, typeStay: null, persons: 0 });
    const [showGuestsPopup, setShowGuestsPopup] = useState(false);
    const ref = useClickOutside(() => setShowGuestsPopup(false));
    const [data, setData] = useState([]);
    const [subDisabled, setSubDisabled] = useState(true);

    const [landOptions, setLandOptions] = useState([]);
    const [subOptions, setSubOptions] = useState([]);
    const [stayOptions, setStayOptions] = useState([]);

    function handleSubOption(newValue) {
        //Get array object which has same value as newValue;
        const selectedOption = landOptions.find(option => option.value === newValue);
        if (selectedOption) setSubOptions(selectedOption['sub-menu']);
        searchQuery.location = selectedOption['sub-menu'][0].value
    }

    useEffect(() => {
        fetch(`/wp-json/v1/accommodations/filter/home`)
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLandOptions(data['land_options']);
                setStayOptions(data['stay_options']);
            })
    }, []);



    // useEffect(() => {
    //     fetch(`/wp-json/v1/accommodations/filter/type-stays?skip_cache=1`)
    //         .then(response => response.json())
    //         .then(data => {
    //             let typeStaysArray = [];
    //             for (const property in data) {
    //                 typeStaysArray = [...typeStaysArray, {
    //                     value: property,
    //                     label: data[property]
    //                 }]
    //             }
    //             return typeStaysArray;
    //         })
    //         .then(typeStays => setTypeStays(typeStays))
    // }, [])

    const searchActionHandler = (e) => {
        e.preventDefault();

        const { location, typeStay } = searchQuery;
        let queryString = "";

        //Create querystring for location
        if (location) {
            queryString += queryString !== "" ? "&" : "";
            queryString += location;
        }

        //Create querystring for typeStays
        if (typeStay) {
            queryString += queryString !== "" ? "&" : "";
            queryString += typeStay;
        }

        window.location.href = `/accommodaties/?${queryString}`;
    }

    return (
        <section className="hero_single version_3 custom_header_image">
            <div className="wrapper">
                <div className="container">
                    <form onSubmit={(e) => searchActionHandler(e)}>
                        <div className="row no-gutters custom-search-input-2 front-page-search-form">
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <Select
                                        data={landOptions}
                                        placeholder='Land'
                                        onChange={(newValue) => {
                                            handleSubOption(newValue);
                                            setSubDisabled(false)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <Select
                                        disabled={subDisabled}
                                        data={subOptions}
                                        placeholder='Provincie of Regio'
                                        value={searchQuery.location} // Set the selected value
                                        onChange={(newValue) => {
                                            setSearchQuery((prevState) => ({
                                                ...prevState,
                                                location: newValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <Select
                                        data={stayOptions}
                                        placeholder='Type accommodatie'
                                        value={searchQuery.typeStay} // Set the selected value
                                        onChange={(typeStay) => setSearchQuery((prevState => {
                                            return {
                                                ...prevState,
                                                typeStay: typeStay
                                            }
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-1 front-search-btn">
                                <input type="submit" className="btn_search" value="Zoek" />
                            </div>
                        </div>
                    </form>
                    <a href="/zoeken-op-de-kaart"><button className="front-page-map-btn d-sm-none col-lg-2">Zoek op de kaart <i className="icon_pin_alt" /></button></a>
                    <a href="/zoeken-op-de-kaart"><button className="front-page-map-btn-phone col-lg-2 d-block d-sm-block d-lg-none ">Of zoek op de kaart <i className="icon_pin_alt" /></button></a>
                </div>
            </div>
        </section>
    )
}

document.addEventListener('DOMContentLoaded', () => {
    const entry = document.querySelector('#front-page-hero-search');
    ReactDOM.render(<FrontPageHeroSearch />, entry);
});
